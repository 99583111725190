<template>
  

  <div class="grid">
    <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#173EA3"
    :is-full-page="fullPage"
  ></loading>
    <div class="col-12">
      <div class="card">
        <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
                <InputText
                  type="text"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  maxlength="10"
                  v-model="mobile_number"
                />
                  <label for="m_no">Mobile Number</label>
                </span>                
            </div>&nbsp;

            <div class="my-2">
            <Button
                icon="pi pi-search"
                v-tooltip.bottom="'Search'"
                
                @click="search(1)"
              />
          
            </div>
          </template>

          <template v-slot:end>
            <Button
                
                icon="pi pi-plus"
                v-tooltip.bottom="'Add'"
                @click="openNew"
              />
           
          </template>
        </Toolbar>
       
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Manage User Master</b></h5>
              
              
              <Button icon="pi pi-refresh"   v-tooltip.bottom="'Refresh'" @click="search(2)" />
        
            </div>
            <div style="text-align:right;font-weight:bold"><span>Total Users:</span>{{totalRecords}}</div>	
          </template>
        
          <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[14]" >
            <template #body="{ data }"> 
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
           
          <Column header="User Type" :field="columns[9]" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.user_type }}
            </template>
          </Column>
          <Column
            header="Name"
            style="min-width: 5rem"
            >
            <template #body="{ data }">
              {{ data.full_name }}
            </template>
          </Column>
          
        
          <Column header="Mobile" :field="columns[9]" style="text-align:right;">
            <template #body="{ data }">
              {{ data.mobile_number }}
            </template>
          </Column>
          <Column header="Email" :field="columns[10]">
            <template #body="{ data }">
              {{ data.email_id }}
            </template>
          </Column>

          <Column header="Action" headerStyle="min-width:10rem;">
            <template #body="slotProps">
          
              <Button
                  icon="pi pi-pencil"
                  title="Edit Client"
                  v-tooltip.bottom="'Edit'"
                  class="p-button-rounded p-button-help mr-2"
                  @click="editProduct(slotProps.data)"
                />
              <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog header="User" v-model:visible="productDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '70vw'}" :modal="true" @hide="reset">
            
            <div class="col-12">
                <div class="card">
                <div class="p-fluid formgrid grid">                  

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="firstname2" type="text" v-model="product.full_name"
                                :class="{ 'p-invalid': submitted && !product.full_name }" />
                            <label for="firstname2">Full Name</label>
                        </span>
                    </div>
                    
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="email" type="text" v-model="product.email_id"
                                :class="{ 'p-invalid': submitted && !product.email_id }" />
                            <label for="email">Email</label>
                        </span>
                    </div>

                    <div v-if="!product._id" class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="password" type="password" v-model="product.password"
                                :class="{ 'p-invalid': submitted && !product.password }" />
                            <label for="password">Password</label>
                        </span>
                    </div> 
                    
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                          <InputText
                              type="text"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              maxlength="10"
                              v-model="product.mobile_number"
                              :class="{ 'p-invalid': submitted && !product.mobile_number }"
                          />
                           
                            <label for="mobile_number">Mobile Number</label>
                        </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                      <span class="p-float-label">
                        <Dropdown id="gender" v-model="gender_dropdownItem" :options="gender_dropdownItems"
                            optionLabel="name" 
                            filter
                            :class="{ 'p-invalid': submitted && !gender_dropdownItem }">
                          </Dropdown>
                          <label for="gender">Gender</label>
                      </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <Dropdown 
                              id="status"
                              v-model="selectstatus"
                              :options="dropdownItems"
                              :value="dropdownItems.value"
                              optionLabel="name"
                              filter
                              :class="{ 'p-invalid': submitted && !selectstatus }"
                              required="true"
                              >
                            </Dropdown>
                            <label for="status">Status</label>
                        </span>
                    </div> 

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Calendar                            
                          v-model="product.dob"
                          dateFormat="dd-mm-yy"
                          :showIcon="true"
                          :showTime="false"
                          hourFormat="12"
                        />
                        <label for="dob">Birth Date</label>
                    </span>
                    </div>

                    <div class="field col-12" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <Textarea id="address" rows="4" v-model="product.address"
                                :class="{ 'p-invalid': submitted && !product.address }" />
                            <label for="address">Address</label>
                        </span>
                    </div>
                    </div>
                </div>
            </div>               
        
          <template #footer>
            <Button v-if="product._id" label="Update" @click="adduser" icon="pi pi-check" class="p-button-warning"/>
            <Button v-else label="add" @click="adduser" icon="pi pi-check" class="p-button-warning"/>
          </template>
        </Dialog>


        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.firsT_NAME }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteuser"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";

import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      
      user_key:"",
      client_id_dropdown: [],
      client_id_search:'',
      hide_dropdown:false,

      mobile_number:'',
      page_no:1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      user_type_search:'',
      file_attachment: "",
      user_type_dropdown: [
					{name: 'Rider', code: 'Rider'},
					{name: 'Driver', code: 'Driver'},
					{name: 'Owner', code: 'Owner'},
					{name: 'Driver Owner', code: 'Driver Owner'}
				],
    
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      gender_dropdownItems: [
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' },
          { name: 'Other', value: 'Other' }
      ],
      gender_dropdownItem: { name: "", value: "" },
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
      BreadcrumbService : null,
      breadcrumbHome: {},
      breadcrumbItems: [],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.user_key=localStorage.getItem("user_key");

    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.UserMaster()
    this.breadcrumbHome=Breadcrum_data.home;
    this.breadcrumbItems=Breadcrum_data.items; 
  },
  async mounted() {
    this.id = this.$route.params.id;
    

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
           isNumber(evt) {  
      const charCode = evt.which ? evt.which : evt.keyCode;  
      if (  
        charCode > 31 &&  
        (charCode < 48 || charCode > 57) &&  
        charCode !== 46  
      ) {  
        evt.preventDefault();  
      }  
    },  

    async search(count)
    { 
      if (count==2) {
      this.user_type_search='';
      this.mobile_number='';
      this.client_id_search='';
     }
      if(this.mobile_number!='' && this.mobile_number.length!=10)
      {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Valid Mobile Number",
          life: 3000,
        });
        return false;
      }
      await this.get_list();
      await this.get_count();
    },
    async viewProduct(product)
    {

      // var data=[product]
      console.log(JSON.stringify(product));
      await localStorage.setItem('savedata',JSON.stringify(product));
    this.$router.push({name:"userProfile"});
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
    //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var type='';
      if(this.user_type_search!='')
      {
        type=this.user_type_search.code;
      }
      var data = {
        id: localStorage.getItem("id"),
        count: true,
        user_type:type,
        mobile_number:this.mobile_number,
      };
      this.loading = true;
      var promise = apis.adminuserlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var type='';
      if(this.user_type_search!='')
      {
        type=this.user_type_search.code;
      }
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        mobile_number:this.mobile_number,
      };
      this.loading = true;
      var promise = apis.adminuserlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      // this.$router.push({ name: "user_add" });
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async adduser() {
      this.submitted = true;
      var mobile_reg=/^[0-9]{10}$/;
      var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      if (!this.product.full_name) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter First Name",
          life: 3000,
        });
        return false;
      }
      
      // if (!this.product.address) {
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "error",
      //     detail: "Please Enter address",
      //     life: 3000,
      //   });
      //   return false;
      // }
      // if (!this.product.mobile_number) {
      //   this.$toast.add({
      //     severity: "error",
      //     summary: "error",
      //     detail: "Please Enter mobile",
      //     life: 3000,
      //   });
      //   return false;
      // }

      if (this.product.mobile_number=='' || this.product.mobile_number==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please enter mobile number",
              life: 3000,
          });
          return false;
        }

        if (this.product.mobile_number) 
            {   
              if (!mobile_reg.test(this.product.mobile_number) || this.product.mobile_number.length!=10) 
              {
                      this.$toast.add({
                      severity: "error",
                      summary: "error",
                      detail: "Please Enter Valid Mobile Number",
                      life: 3000,
                  });
                  return false;
              }                
            }

      if (this.product.email_id=='' || this.product.email_id==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Email ID",
              life: 3000,
          });
          return false;
        }

        if (this.product.email_id) 
        {   
            if (!emailreg.test(this.product.email_id)) 
            {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Valid Email ID",
                    life: 3000,
                });
                return false;
            }                
        }

      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000,
        });
        return false;
      }

      let dateTimeString = '2024-02-01';
      if (this.product.dob) {
        let dateObject = new Date(this.product.dob);
        this.product.dob = dateObject.toISOString().split('T')[0];
      }

      //edit
      if (this.product._id) {
        // alert("edit");
        var data = {
          _id: this.product._id,
          full_name: this.product.full_name,
          email_id: this.product.email_id,
          mobile_number: this.product.mobile_number,
          gender: this.gender_dropdownItem.value,
          user_type:"Admin",
          address: this.product.address,
          status: this.selectstatus.value,
          dob:this.product.dob,
          updated_by: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        this.reset();
        // return false;
        var promise = apis.updateUser(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        // alert("add");
        var data1 = {
          full_name: this.product.full_name,
          email_id: this.product.email_id,
          mobile_number: this.product.mobile_number,
          gender: this.gender_dropdownItem.value,
          password: this.product.password,
          user_type:"Admin",
          reset_password:"",
          address: this.product.address,
          status: this.selectstatus.value,
          dob:this.product.dob,
          created_by: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        this.reset();
        var promises = apis.addUser(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          // this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    reset()
    {
        this.selectstatus={ name: "", value: "" };
        this.gender_dropdownItem={ name: "", value: "" };
        this.attachments="";
    },
   async editProduct(product) {
      this.product = { ...product };
      this.selectstatus.value = product.status;
      this.selectstatus.name = product.status;
      this.gender_dropdownItem.value = this.product.gender;
      this.gender_dropdownItem.name = this.product.gender;
        this.productDialog = true;

        // await localStorage.setItem('saveuserdata','');
        // await localStorage.setItem('saveuserdata',JSON.stringify(product));
        // console.log("localstorage",localStorage.getItem('saveuserdara'));
        // this.$router.push({name:"user_edit"});
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product._id) {
        var data = {
          _id: this.product._id,
        };
        this.isLoadingModel = true;
        var promise = apis.deleteusermaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    
  async  exportCSV() {
      this.limit=this.totalRecords;
      this.get_list();
     await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {downloadExcel: JsonExcel},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
